























































































































import { Component, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import AccessTypeButton from "@/components/Button/AccessTypeButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { ARCHIVE_OPTIONS } from "@/constants/ArchiveOptions";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import ModalMixin from "@/mixins/ModalMixin";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";
import PrintButton from "@/components/Button/PrintButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { getFile } from "@/utils/File";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { formatInstructorName } from "@/utils/NameUtil";
import Avatar from "@/components/Avatars/Avatar.vue";
import { UserTypeEnum } from "@/enums/UserTypeEnum";
import { IInstructor } from "@/interfaces/IInstructor";

const InstructorModule = namespace("instructor");
const InstructorFilterModule = namespace("instructor/localFilter");
const MainTabsModule = namespace("main-tabs");

@Component({
  computed: {
    UserTypeEnum() {
      return UserTypeEnum;
    },
  },
  components: {
    Avatar,
    ArchiveModal,
    FilterAndSearch,
    ArchiveButton,
    FscPageHeader,
    FscCard,
    FscSimpleCard,
    AccessTypeButton,
    RemoveButton,
    EditButton,
    CreateButton,
    Actions,
    Table,
    PrintButton,
    Datepicker,
    AbortButton,
    SaveButton,
    Validation,
  },
})
export default class Instructors extends mixins(ModalMixin, CompanyMixin) {
  public name = "Instructors";

  public fields = [
    {
      key: "lastName",
      label: this.$t("sidebar.instructors"),
      sortable: true,
    },
    {
      key: "initials",
      label: this.$t("general.initials"),
      sortable: true,
    },
  ];

  @InstructorFilterModule.State("filter")
  public instructorLocalFilter: any;

  @InstructorFilterModule.Mutation("SET_LOCAL_FILTER")
  public setInstructorLocalFilter!: (payload: Record<any, any>) => void;

  @InstructorModule.Action("filter")
  public filterAction: any;

  @InstructorModule.Getter("getDataList")
  public instructors: any;

  @InstructorModule.Getter("getTotal")
  public totalRows: any;

  @InstructorModule.Getter("getIsLoading")
  public loading!: boolean;

  @InstructorModule.Action("archive")
  public archive!: (options: ICrudOptions) => Promise<void>;

  @InstructorModule.Getter("getArchiveSuccess")
  public getArchiveSuccess!: boolean;

  @MainTabsModule.Action("setCustomLabel")
  private setCustomLabelAction: any;

  public selectedRow: any = null;

  public query: any = null;
  public archived = false;
  public filtered = "";

  public selectedInstructorIds: Array<number> = [];
  public fromDate: any = null;
  public toDate: any = null;

  public mounted() {
    if (this.instructorLocalFilter instanceof Object) {
      this.archived = this.instructorLocalFilter.archived;
    }
  }

  public requestQuery(query: Record<any, any>): void {
    this.selectedInstructorIds = [];
    this.query = { ...query, archived: this.archived };
    this.filterAction({ resource: "instructor", filter: this.query });
  }

  public onRowClicked(ctx: any): void {
    this.selectedRow = ctx;
  }

  public onEdit(): void {
    if (!this.selectedRow) return;

    this.setCustomLabelAction(formatInstructorName(this.selectedRow.firstName, this.selectedRow.lastName));

    this.$router.push({
      name: "InstructorEdit",
      params: { id: this.selectedRow.id },
    });
  }

  private async onArchive(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    if (!this.selectedRow) return;

    await this.archive({
      resource: "users",
      params: { archived: !this.archived, id: this.selectedRow.id },
    });

    if (this.getArchiveSuccess) {
      this.requestQuery(this.query);
    }
  }

  private onChangeFilter(): void {
    this.setInstructorLocalFilter({ archived: this.archived });
    this.requestQuery({ ...this.query, archived: this.archived });
  }

  public get archivedOption(): Array<any> {
    return ARCHIVE_OPTIONS;
  }

  protected onSearch(): void {
    (this.$refs.instructorTable as Table).onSearch(this.filtered);
  }

  private showArchiveModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("instructor-archive-modal");
  }

  private onPrintButtonClicked(): void {
    this.$bvModal.show("printModal");
  }

  private onAbort(): void {
    this.$bvModal.hide("printModal");
    this.fromDate = null;
    this.toDate = null;
  }

  private async onSubmit(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const data = {
      instructorIds: this.selectedInstructorIds,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    this.$bvModal.hide("printModal");
    this.$bvModal.show("loadingModal");
    await getFile({
      method: "POST",
      url: `/instructor/export`,
      data: data,
    }).finally(() => {
      this.$bvModal.hide("loadingModal");
    });
    this.fromDate = null;
    this.toDate = null;
    this.$v.$reset();
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: { required },
      toDate: { required },
      selectedInstructorIds: { required },
    };
  }

  // @Watch("$route", { immediate: true, deep: true })
  // protected onUrlChange(): void {
  //   if (this.$route.name !== "InstructorEdit" && this.selectedRow) {
  //     this.requestQuery({ ...this.query, archived: this.archived });
  //   }
  // }

  public instructorFullName(instructor: IInstructor) {
    return `${instructor.lastName} ${instructor.firstName}`;
  }
}
